<script>
export default {
  created () {
    this.gotoStore()
  },
  methods: {
      gotoStore(){
            var u = navigator.userAgent
                var isAndroid =
                    u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
                if (isAndroid) {
                    window.location.href =
                        'https://btcbay.oss-cn-hongkong.aliyuncs.com/Btcbay/btcbay.apk'
                }
                if (isiOS) {
                    window.location.href =
                        'https://itunes.apple.com/cn/app/id1500855083?mt=8'
                }
      }
  }
}
</script>
